import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TokenService, LogService, UtilsService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import {
    AuthenticateModel,
    AuthenticateResultModel,
    TokenAuthServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { AuthService } from '@app/pages/services/auth.service';

@Injectable()
export class AppAuthService {
    authenticateModel: AuthenticateModel;
    authenticateResult: AuthenticateResultModel;
    rememberMe: boolean;

    constructor(
        private _tokenAuthService: TokenAuthServiceProxy,
        private _router: Router,
        private _utilsService: UtilsService,
        private _tokenService: TokenService,
        private _logService: LogService,
        private _authService:AuthService
    ) {
        this.clear();
    }

    logout(reload?: boolean): void {
        /* localStorage.removeItem("token"); */
        abp.auth.clearToken();
        abp.utils.deleteCookie(AppConsts.authorization.encryptedAuthTokenName);

        if (reload !== false) {
            location.href = AppConsts.appBaseUrl;
        }
    }


    authenticate(finallyCallback?: () => void): void {
         let authorizationData = 'Basic ' + btoa(this.authenticateModel.userNameOrEmailAddress + ':' + this.authenticateModel.password);
         localStorage.setItem('basic', authorizationData);
        const data = {
          appId: "EFDBBC9D-94F0-4D12-A54F-34B520A7789B",
          deviceId: "1.1.1.1",
          latitud: "0",
          longitud: "0"
        }

        this._authService.authenticate(data,authorizationData).pipe(
          finalize(() => {
              finallyCallback();
          })
       ).subscribe((result: AuthenticateResultModel) => {
            localStorage.removeItem("basic");
            localStorage.setItem("token",  result["result"]["token"]);

            this._tokenAuthService.authenticate(this.authenticateModel)
            .pipe(
                finalize(() => {
                    finallyCallback();
                })
            )
            .subscribe((resultABP: AuthenticateResultModel) => {
                this.processAuthenticateResult(resultABP);
            });
    });

    }

    private processAuthenticateResult(
        authenticateResult: AuthenticateResultModel
    ) {

        this.authenticateResult = authenticateResult;

        if (authenticateResult.accessToken) {
            // Successfully logged in

            this.login(
                authenticateResult.accessToken,
               authenticateResult.encryptedAccessToken,
                authenticateResult.expireInSeconds,
                this.rememberMe
            );
        } else {
            // Unexpected result!

            this._logService.warn('Unexpected authenticateResult!');
            this._router.navigate(['account/login']);
        }
    }

    private login(
        accessToken: string,
          encryptedAccessToken: string,
        expireInSeconds: number,
        rememberMe?: boolean
    ): void {
        const tokenExpireDate = rememberMe
            ? new Date(new Date().getTime() + 1000 * expireInSeconds)
            : undefined;

        this._tokenService.setToken(accessToken, tokenExpireDate);

          this._utilsService.setCookieValue(
            AppConsts.authorization.encryptedAuthTokenName,
            encryptedAccessToken,
            tokenExpireDate,
            abp.appPath
        );

        let initialUrl = UrlHelper.initialUrl;
        if (initialUrl.indexOf('/login') > 0) {
            initialUrl = AppConsts.appBaseUrl;
        }

          location.href = initialUrl;
    }

    private clear(): void {
        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.rememberClient = false;
        this.authenticateResult = null;
        this.rememberMe = false;
    }
}
