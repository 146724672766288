// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    hmr: false,
    appConfig: 'appconfig.json',
    appBaseUrl: "http://localhost:4200",
    services: {
        // Authentication Service URL
        authUrl: 'https://authfutbolapi.futbol-card.com',
        userUrl: 'https://usersapi-futbol.futbol-card.com',
        envelopesUrl: 'https://envelopesapi.futbol-card.com',
        subscriptionUrl: 'https://subscriptionsapi-futbol.futbol-card.com',
        notificationUrl:'https://notificationapi-futbol.futbol-card.com',
        albumsapiUrl:'https://albumsapi.futbol-card.com'
      },
};
