
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  url401 = '';
  reload = 0;
  routerStateSnapshot: any;
  authUrl: string = environment.services.authUrl;
  constructor(
    private datosLogin: AuthService,
    private router: Router
  ) {
    this.routerStateSnapshot = this.router.routerState.snapshot;
  }

  intercept(req, next) {
    let request = req;

    const basic = localStorage.getItem("basic")
    const token = localStorage.getItem("token");

    if (basic) {
      const tokenReq = req.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `${basic}`
        }
      });
      return next.handle(tokenReq);
    }


    if (request.url.includes(`abpUserConfiguration/GetAll`) ||
      request.url.includes(`Role/GetAll`) ||
      request.url.includes(`Session/GetCurrentLoginInformations`) ||
      request.url.includes(`assets/appconfig.json`)

    ) {
      return next.handle(request);
    }

    if (token) {
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    if (this.reload > 30) {
      setTimeout(x => {
        this.reload = 0;

      }, 10000)
      return EMPTY
    } else {
      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.status === 200) {
            this.url401 = '';
            this.reload = 0;
          }
        }),
        catchError((err: HttpErrorResponse) => {

          if (err.status === 401) {
            if (this.url401 == request.url) {
              if (this.reload > 12) {
                this.reload = 0;
              } else {
                this.reload++;
              }
            } else {
              this.url401 = request.url;
              this.reload = 0;
            }
            localStorage.removeItem('token')
            if (this.router.url == '/account/login' || this.router.url == '/') {
            } else {
              this.router.navigate(['/account/login'], { queryParams: { returnUrl: this.routerStateSnapshot.url } })
            }
          }

          return throwError(err);

        })
      );
    }

  }
}
