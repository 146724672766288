import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PaginatorResult } from 'app/pages/models/callBack.model';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { AuthenticateModel, AuthenticateResultModel } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
import { TokenService, UtilsService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //api url
  authUrl: string = environment.services.authUrl;
  userUrl: string = environment.services.userUrl;
  constructor(private http: HttpClient,
    ) { }

  authenticate(data,header): Observable<AuthenticateResultModel> {
    return this.http.post(`${this.authUrl}/login`, data,header) as any as Observable<AuthenticateResultModel>;
  }


}
